/*
|--------------------------------------------------------------------------
| Store > Booking > Actions
|--------------------------------------------------------------------------
|
| This file is where the Module's action methods are registered.
|
| Feel free to edit this file if you have action methods you want
| to include.
|
*/

import loadCustomerNormalBookings from './loadCustomerNormalBookings'
import loadCustomerAndTranslatorOtherBookings from './loadCustomerAndTranslatorOtherBooking'
import loadCustomerEmergencyBookings from './loadCustomerEmergencyBookings'
import loadCustomerHistoricBookings from './loadCustomerHistoricBookings'
import loadTranslatorPotentialBookings from './loadTranslatorPotentialBookings'
import loadTranslatorNormalBookings from './loadTranslatorNormalBookings'
import loadTranslatorHistoricBookings from './loadTranslatorHistoricBookings'
import loadLanguageOpts from './loadLanguageOpts'
import loadDeepLLanguagesOpts from './loadDeepLLanguagesOpts'
import loadRecruitmentLanguageOpts from './loadRecruitmentLanguageOpts'
import validateBooking from './validateBooking'
import validateConveyBooking from './validateConveyBooking'
import validateDuplicatedBooking from './validateDuplicatedBooking'
import submitBooking from './submitBooking'
import acceptBooking from './acceptBooking'
import acceptBookingPublic from './acceptBookingPublic'
import fetchAppointmentTemplate from './fetchAppointmentTemplate'
import rejectBookingPublic from './rejectBookingPublic'
import rejectBooking from './rejectBooking'
import updateBooking from './updateBooking'
import endBooking from './endBooking'
import cancelBooking from './cancelBooking'
import withdrawBooking from './withdrawBooking'
import submitFeedback from './submitFeedback'
import submitFeedbackToDT from './submitFeedbackToDT'
import submitFeedbackPublic from './submitFeedbackPublic'
import getBookingPublic from './getBookingPublic'
import getBookingTravelTime from './getBookingTravelTime'
import getBookingDetails from './getBookingDetails'
import getBookingIssueDetails from './getBookingIssueDetails'
import getBookingByTranslatorAndCustomer from './getBookingByTranslatorAndCustomer'
import calculateTravel from './calculateTravel'
import updateTravelTime from './updateTravelTime'
import updateSessionTime from './updateSessionTime'
import updateSessionTimePublic from './updateSessionTimePublic'
import deleteConfirmFill from './deleteConfirmFill'
import acceptAnotherTranslator from './acceptAnotherTranslator'
import loadCustomerUpcomingBookings from './loadCustomerUpcomingBookings'
import removeTranslatorFromBooking from './removeTranslatorFromBooking'
import removeTranslatorsFromBooking from './removeTranslatorsFromBooking'
import addMessage from './addMessage'
import updateMessage from './updateMessage'
import deleteMessageFile from './deleteMessageFile'
import deleteConveyFile from './deleteConveyFile'
import updateConveyJob from './updateConveyJob'
import getBookingIssueFiles from './getBookingIssueFiles'
import getConfirmationFills from './getConfirmationFills'
import downloadInvoicePdf from './downloadInvoicePdf'
import notCarriedOutToCustomer from './notCarriedOutToCustomer'
import loadCustomerRecentBookings from './loadCustomerRecentBookings'
import loadSalaryPreview from './loadSalaryPreview'
import submitPrefillSalaryItems from './submitPrefillSalaryItems'
import redoPreferences from '@/modules/booking/store/booking/actions/redoPreferences'
import cancelBookingDueToPreference from '@/modules/booking/store/booking/actions/cancelBookingDueToPreference'
import initializeLanguages from './initializeLanguages'
import getBookingIssuePublic from '@/modules/booking/store/booking/actions/getBookingIssuePublic'
import addBookingIssueCommentPublic from '@/modules/booking/store/booking/actions/addBookingIssueCommentPublic'
import loadBookingsGeneric from './loadBookingsGeneric'
import submitBookingComment from './submitBookingComment'
import bookingExport from './bookingExport'
import submitRecurringBookings from './submitRecurringBookings'
import validateRecurringBookings from './validateRecurringBookings'
import submitMultipleBookings from './submitMultipleBookings'
import validateMultipleBooking from './validateMultipleBooking'
import validateOnUpdate from './validateOnUpdate'
import loadSalarySetting from './loadSalarySetting'
import getLateCancelRules from '@/modules/booking/store/booking/actions/getLateCancelRules'
import checkConflict from './checkConflict'
import checkTranslatorExpenseSettings from './checkTranslatorExpenseSettings'
import getTowns from '@/modules/auth/store/actions/getTowns'
import loadBookingFilesFromProfile from './loadBookingFilesFromProfile'
import availableTranslators from './availableTranslators'
import getSuggestedTravelTime from './getSuggestedTravelTime'
import countTotalBookings from './countTotalBookings'
import createGuestBooking from './createGuestBooking'
import loadPriceQuote from './loadPriceQuote'
import getPotentialBookingCostEstimation from './getPotentialBookingCostEstimation'
import submitTravelTimeMessage from './submitTravelTimeMessage'
import getSuggestedTravelTimePublic from './getSuggestedTravelTimePublic'
import getTranslatorBatchEntry from './getTranslatorBatchEntry'
import getBookingTravelCalculation from './getBookingTravelCalculation'
import downloadMultipleInvoices from './downloadMultipleInvoices'
import getDeviationsSettings from './getDeviationsSettings'
import loadBookingsWithTimeSlot from './loadBookingsWithTimeSlot'
import getBookingAssigmentProbability from './getBookingAssigmentProbability'

export default {
  loadBookingsGeneric,
  loadCustomerNormalBookings,
  loadCustomerAndTranslatorOtherBookings,
  loadCustomerEmergencyBookings,
  loadCustomerHistoricBookings,
  loadTranslatorPotentialBookings,
  loadTranslatorNormalBookings,
  loadTranslatorHistoricBookings,
  loadLanguageOpts,
  loadDeepLLanguagesOpts,
  loadRecruitmentLanguageOpts,
  validateBooking,
  validateConveyBooking,
  validateDuplicatedBooking,
  submitBooking,
  acceptBooking,
  acceptBookingPublic,
  fetchAppointmentTemplate,
  rejectBookingPublic,
  rejectBooking,
  updateBooking,
  endBooking,
  cancelBooking,
  withdrawBooking,
  submitFeedback,
  submitFeedbackPublic,
  submitFeedbackToDT,
  getBookingPublic,
  getBookingTravelTime,
  getBookingDetails,
  getBookingIssueDetails,
  getBookingByTranslatorAndCustomer,
  calculateTravel,
  updateTravelTime,
  updateSessionTime,
  updateSessionTimePublic,
  deleteConfirmFill,
  acceptAnotherTranslator,
  loadCustomerUpcomingBookings,
  removeTranslatorFromBooking,
  removeTranslatorsFromBooking,
  addMessage,
  updateMessage,
  deleteMessageFile,
  deleteConveyFile,
  updateConveyJob,
  getBookingIssueFiles,
  getConfirmationFills,
  downloadInvoicePdf,
  loadCustomerRecentBookings,
  notCarriedOutToCustomer,
  loadSalaryPreview,
  loadSalarySetting,
  submitPrefillSalaryItems,
  redoPreferences,
  cancelBookingDueToPreference,
  getBookingIssuePublic,
  addBookingIssueCommentPublic,
  bookingExport,
  submitRecurringBookings,
  validateRecurringBookings,
  submitMultipleBookings,
  validateMultipleBooking,
  validateOnUpdate,
  initializeLanguages,
  submitBookingComment,
  getLateCancelRules,
  checkConflict,
  checkTranslatorExpenseSettings,
  getTowns,
  loadBookingFilesFromProfile,
  availableTranslators,
  createGuestBooking,
  loadPriceQuote,
  getPotentialBookingCostEstimation,
  getSuggestedTravelTime,
  countTotalBookings,
  submitTravelTimeMessage,
  getSuggestedTravelTimePublic,
  getTranslatorBatchEntry,
  getBookingTravelCalculation,
  downloadMultipleInvoices,
  getDeviationsSettings,
  loadBookingsWithTimeSlot,
  getBookingAssigmentProbability
}
