<template>
  <div :class="blockClass">
    <template v-if="splitView">
      <div :class="getBem(blockClass, 'current-date-time')">
        <p class="title bold">{{ $t(isTranslatorAssigned ? 'selected_time_slot' : 'start_end_time') }}:</p>
        <TimeSlot
          :non-acceptance-reason="getNonAcceptanceReason()"
          :date="booking.due_date"
          :start-time="formatTime(booking.due)"
          :end-time="bookingEndTime"
          :is-selected="true"
        />
      </div>
    </template>

    <div>
      <p class="title bold">{{ $t('all_potential_time_slots') }}:</p>
      <div v-for="(slot, index) in bookingTimeSlots" :key="index">
        <TimeSlot
          :non-acceptance-reason="
            isTranslatorAssigned || isEmpty(slot.reason)
              ? ''
              : `${$t(`${slot.reason}_reason`, { booking: slot.conflict_booking })}`
          "
          :date="slot.date"
          :is-selected="isSelected(slot)"
          :start-time="formatTime(`${slot.date} ${slot.start_time}`)"
          :end-time="formatTime(`${slot.date} ${slot.end_time}`)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { formatTime } from '@/modules/helpers/common'
import { find, isEmpty, isNil, isEqual } from 'lodash'
import TimeSlot from './TimeSlot'
import sortBy from 'lodash/sortBy'
import { mapGetters } from 'vuex'

export default {
  components: { TimeSlot },
  /*
  |--------------------------------------------------------------------------
  | Component > props
  |--------------------------------------------------------------------------
  */
  props: {
    booking: {
      type: Object,
      default: () => {}
    },

    splitView: {
      type: Boolean,
      default: false
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data() {
    return {
      blockClass: 'booking-time-slots'
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed: {
    ...mapGetters('auth', ['isUserCustomer', 'isUserTranslator', 'user']),
    bookingEndTime() {
      let willEndAt = this.booking?.will_end_at ?? ''
      let endedAT = this.booking?.ended_at ?? ''
      let endTime = endedAT ? endedAT : willEndAt
      return endTime ? this.formatTime(endTime) : ''
    },

    bookingTimeSlots() {
      let filtered = this.booking?.booking_time_slots
        ?.filter((timeSlot) => {
          return !timeSlot.translator?.translator_unavailable_times.some(
            (unavailableTime) =>
              new Date(`${timeSlot.date} ${timeSlot.start_time}`) >= new Date(unavailableTime.unavailable_from) &&
              new Date(`${timeSlot.date} ${timeSlot.start_time}`) <= new Date(unavailableTime.unavailable_until)
          )
        })
        ?.filter((i) => i.is_valid)
      if (this.booking.type === 'text_translation' && isEmpty(filtered)) {
        return []
      }
      let timeSlots = [...filtered]
      if (this.isUserTranslator) {
        timeSlots = timeSlots.filter((i) => i.translator_id === this.user.id)
      }
      const sortedItems = sortBy(timeSlots, (item) => {
        const [hours, minutes] = item.start_time.split(':')
        return parseInt(hours) * 60 + parseInt(minutes)
      })
      let acceptableBooking = this.booking.acceptable_booking_dates?.filter((i) => i.is_valid)
      if (this.isUserTranslator) {
        acceptableBooking = acceptableBooking?.filter((i) => i.translator_id === this.user.id)
      }
      return acceptableBooking ?? sortedItems
    },

    isTranslatorAssigned() {
      return !isEmpty(this.booking.assigned_translator)
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > methods
  |--------------------------------------------------------------------------
  */
  methods: {
    formatTime,
    isEmpty,

    getNonAcceptanceReason() {
      if (this.isTranslatorAssigned) {
        return ''
      }
      const condition = {
        date: this.booking.due_date,
        start_time: this.booking.due_time,
        end_time: this.booking.end_time
      }

      const reason = find(this.booking?.acceptable_booking_dates ?? [], condition)?.reason ?? ''
      const bookings = find(this.booking?.acceptable_booking_dates ?? [], condition)?.conflict_booking ?? ''

      return reason ? this.$t(`${reason}_reason`, { booking: bookings }) : ''
    },

    isSelected(slot) {
      return (
        !isNil(this.booking.status) &&
        this.booking.status.code === 'assigned' &&
        isEqual(slot, {
          date: this.booking.due_date,
          start_time: this.booking.due_time,
          end_time: this.booking.end_time
        })
      )
    }
  }
}
</script>

<style lang="scss">
.booking-time-slots {
  padding: 10px 0 0 0;

  .title {
    margin-top: 5px !important;
  }

  &__current-date-time {
    .title {
      margin-top: 0;
    }

    .el-icon-time {
      padding-right: 5px;
      font-weight: 600;
    }
  }
}
</style>
