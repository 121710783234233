import { createApp, h } from 'vue'

import 'normalize.css/normalize.css' // Should be in this order to prioritize the load
import App from '@/App.vue'
import i18nClient from '@/locales/i18nClient.js'
import router from '@/router/index.js'
import store from '@/store/index.js'
import { createHead } from '@unhead/vue'
import middlewares from '@/router/middlewares/index.js'
import registerGlobalMixins from '@/plugins/globalMixins'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import appLayoutComponents from '@/plugins/appLayoutComponent'
import registerElementUi from '@/plugins/elementUI'
import registerElementPlus from '@/plugins/elementPlusIcons'
import registerInitStoreSetup from '@/plugins/initStoreSetup'
import registerChatwootLive from '@/assets/js/registers/chatwootLive'
// import VueAnnouncer from 'vue-announcer'
import * as Sentry from '@sentry/vue'

import '@dtolk/digitaltolk-ui/style'

import { UseFocusTrap } from '@vueuse/integrations/useFocusTrap/component'
import 'what-input'
import '@/assets/js/lib/modernizr-custom.js' // for detecting following features (webp)

const head = createHead()

const app = createApp({
  render: () => h(App)
})

app.use(store)
// store.dispatch('auth/initToken')

app.use(head)
app.use(i18nClient)
app.use(router)

// PLUGINS
app.use(appLayoutComponents)
app.use(registerGlobalMixins)
app.use(registerElementUi)
app.use(registerElementPlus)

app.use(registerInitStoreSetup, { store, router })

const isLocalHost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
const isUKTenant = window.location.hostname.includes('.uk')

if (!isLocalHost) {
  Sentry.init({
    app,
    dsn:
      import.meta.env.VITE_SENTRY_DSN ||
      'https://66091740141ce53082e9aab857c87db4@o4507523286499328.ingest.de.sentry.io/4507531959926864',
    environment: import.meta.env.VITE_APP_ENVIRONMENT || 'stage',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration()
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: 0.1,
    ignoreErrors: ['/.*Token invalid! Redirecting to login page.*/i']
  })
}

String.prototype.toSentenceCase = function () {
  return this.toLowerCase().replace(/(^\s*|[.!?]\s*)(\w)/g, function (match, separator, char) {
    return separator + char.toUpperCase()
  })
}

router.beforeEach(middlewares)

app.component('UseFocusTrap', UseFocusTrap)

if (!isLocalHost && !isUKTenant) {
  registerChatwootLive()
}

if (isUKTenant) {
  setTimeout(() => {
    const titleElement = document.getElementsByTagName('title')[0]
    if (titleElement) {
      console.log('setting the UK title')
      titleElement.innerText = "Tolk - UK's premier language service provider"
    }
  }, 1000)
}

app.mount('#app')
